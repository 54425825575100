/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'

import {KTIcon} from '../../../../_metronic/helpers'
import {Bar} from 'react-chartjs-2'
import {useDashboardsStore} from '../core/dashboardStore'
import {useAccountsUsersStore} from '../../../modules/settings/core/accountsUsersStore'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {formatNumber, getCurrencySymbol, formatMoney} from '../../../../_helpers/_helpers'

type Props = {
  className: string
  chartType: string | 'auto' | 'sales' | 'bookings'
  chartColor: string | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info'
}

type ChartData = {
  labels: string[]
  datasets: {
    label?: string
    data: number[]
    fill: boolean
    borderColor: string
    borderWidth?: number
    backgroundColor?: string
    tension: number
  }[]
}

const SalesOrBookingsCard: FC<Props> = ({className, chartType, chartColor = 'success'}) => {
  const {dashboardData} = useDashboardsStore()
  const [chartHasData, setChartHasData] = useState(false)
  const {getCurrentAccount} = useAccountsUsersStore()
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        data: [],
        fill: true,
        borderColor: getCSSVariableValue('--bs-success'),
        backgroundColor: getCSSVariableValue('--bs-success-light'),
        borderWidth: 1,
        tension: 0.4,
      },
    ],
  })

  useEffect(() => {
    // CHECK IF THERE'S DATA IN THE SALES CHART  IF NOT SET CHART TYPE TO BOOKINGS

    // if set to auto prefer sales if there is sales data

    let loadSalesChart = false
    if (
      chartType === 'auto' &&
      (dashboardData?.salesChart?.thisWeek.total > 0 ||
        dashboardData?.salesChart?.lastWeek.total > 0)
    ) {
      loadSalesChart = true
    } else {
      loadSalesChart = chartType === 'sales' ? true : false
    }

    if (loadSalesChart === true) {
      setChartData({
        labels: dashboardData?.salesChart?.thisWeek.labels,
        datasets: [
          {
            label: 'This Week',
            data: dashboardData?.salesChart?.thisWeek.series || [],
            fill: true,
            borderColor: getCSSVariableValue(`--bs-${chartColor}`),
            backgroundColor: getCSSVariableValue(`--bs-${chartColor}`),
            borderWidth: 1,
            tension: 0.4,
          },
          {
            label: 'Last Week',
            data: dashboardData?.salesChart?.lastWeek.series || [],
            fill: true,
            borderColor: getCSSVariableValue(`--bs-secondary`),
            backgroundColor: getCSSVariableValue(`--bs-secondary`),
            borderWidth: 1,
            tension: 0.4,
          },
        ],
      })
    } else {
      // refreshChart()
      setChartData({
        labels: dashboardData.bookingsChart?.thisWeek.labels || [],
        datasets: [
          {
            label: 'This Week',
            data: dashboardData?.bookingsChart?.thisWeek.series || [],
            fill: true,
            borderColor: getCSSVariableValue(`--bs-${chartColor}`),
            backgroundColor: getCSSVariableValue(`--bs-${chartColor}`),
            borderWidth: 1,
            tension: 0.4,
          },
          {
            label: 'Last Week',
            data: dashboardData?.bookingsChart?.lastWeek.series || [],
            fill: true,
            borderColor: getCSSVariableValue(`--bs-secondary`),
            backgroundColor: getCSSVariableValue(`--bs-secondary`),
            borderWidth: 1,
            tension: 0.4,
          },
        ],
      })
    }

    setChartHasData(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData])

  return (
    <>
      {chartHasData ? (
        <>
          <div className={`card card-flush  ${className}`}>
            <div className='card-header pt-5 px-5'>
              <div className='card-title w-100 d-flex flex-column'>
                <div className='d-flex w-100 align-items-center'>
                  <div className='d-flex me-1 justify-content-start align-items-start'>
                    {chartType === 'sales' && (
                      <span className='fs-5 fw-bold text-gray-400 me-1 align-self-start'>
                        {getCurrencySymbol(getCurrentAccount()?.currency)}
                      </span>
                    )}
                    <span className='display-5 fw-bold text-dark me-2 lh-1 ls-n2'>
                      {chartType === 'sales'
                        ? formatMoney(
                            dashboardData?.salesChart?.thisWeek.total,
                            getCurrentAccount()?.currency,
                            0,
                            true
                          )
                        : formatNumber(dashboardData?.bookingsChart?.thisWeek.total, 0)}
                    </span>
                  </div>
                  <div className='d-flex flex-grow-1 flex-column fs-7'>
                    <span className='text-muted'>
                      {chartType === 'sales' ? 'Sales' : 'Tickets'}
                    </span>
                    <span className='text-muted'>This Week</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='px-2 pb-5 d-flex flex-wrap'>
              <div className='d-flex w-100'>
                <Bar
                  data={chartData}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        grid: {
                          display: false, // This will remove the grid lines for the x-axis
                          lineWidth: 0, // This will remove the line at the x-axis
                        },
                        border: {
                          display: true, // This will remove the border around the chart
                        },
                        ticks: {
                          display: true, // This will remove the tick labels for the x-axis
                          callback: function (value, index, values) {
                            const date = new Date(this.getLabelForValue(index))
                            const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
                            return days[date.getDay()]
                          },
                        },
                      },
                      y: {
                        grid: {
                          display: false, // This will remove the grid lines for the y-axis
                          lineWidth: 0, // This will remove the line at the y-axis
                          drawOnChartArea: false, // This will remove the y-axis line
                        },
                        border: {
                          display: false, // This will remove the border around the chart
                        },
                        ticks: {
                          display: false, // This will remove the tick labels for the y-axis
                        },
                      },
                    },
                    plugins: {
                      legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                          usePointStyle: true,
                          font: {
                            size: 12,
                          },
                        },
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            let label = context.dataset.label || ''
                            if (label) {
                              label += ': '
                            }
                            if (context.parsed.y !== null && chartType === 'sales') {
                              label += formatMoney(
                                context.parsed.y,
                                getCurrentAccount()?.currency,
                                0,
                                true
                              )
                            } else {
                              label += formatNumber(context.parsed.y, 0, true)
                            }
                            return label
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className={`card card-flush border-dashed border-shadow-0 bg-transparent ${className}`}
        >
          <div className='card-body d-flex flex-center flex-column'>
            <KTIcon iconName='alert-circle' className='fs-2hx text-muted' />
            <span className='text-gray-400 fw-bold fs-3'>No bookings yet</span>
            <span className='text-gray-400 text-center fs-6'>
              Bookings and sales data will appear here.
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export {SalesOrBookingsCard}
